<template>
  <v-app>
    <nfc-snackbar
      id="snackbarForBooster"
      v-model="isOpenSnackbar"
      :timeout="2000"
      :color="getColor"
      :message="getMessage"
    />
    <v-main id="container_main">
      <v-container>
        <div class="loading text-center" v-if="getLoading">
          <div class="full-center">
            <v-progress-circular
              :color="getColor"
              indeterminate
              size="180"
              width="21"
            ></v-progress-circular>
          </div>
        </div>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "pinia";
import { useLoadingStore } from "@/store/loading";
import { useMessageStore } from "@/store/message";
import NfcSnackbar from "@/components/atoms/snackbar/NfcSnackbar.vue";

export default {
  // get meta info from the router editor
  components: {
    NfcSnackbar,
  },
  computed: {
    ...mapState(useLoadingStore, ["getLoading"]),
    getColor() {
      return this.$route.meta.editor ? "primary" : "white";
    },

    ...mapState(useMessageStore, ["getMessage", "getColor", "getIcon"]),
    isOpenSnackbar: {
      get() {
        return this.messageStore.isOpen;
      },
      set(value) {
        this.messageStore.setOpen(value);
      },
    },
  },
  setup() {
    return {
      messageStore: useMessageStore(),
    };
  },

  name: "App",
};
</script>
<style scoped>
.bg-white {
  background: white !important;
}
.bg-code {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 42%,
    rgba(7, 9, 80, 1) 100%
  );
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 9999;
}

.full-center {
  position: absolute;
  top: 170px;
  left: 50%;

  transform: translate(-50%, -50%);
}
</style>

