import { defineStore } from 'pinia'


export const useLoadingStore = defineStore('loading', {
    state: () => ({ loading: true }),
    getters: {
        getLoading: (state) => state.loading,
    },
    actions: {
        setLoading(l) {
            this.loading = l
        },
    },
})