import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import VueQRCodeComponent from 'vue-qrcode-component'
import { i18n } from "./adapters/i18n";
import { createPinia } from 'pinia'
import { VAutocomplete } from 'vuetify/components';

// ...your Vue app creation.

// IMPORTANT: required when treeshaking Vuetify components.

import './assets/css/main.css';







const pinia = createPinia()



loadFonts();



createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .component('qr-code', VueQRCodeComponent)
  .use(vuetify)
  .component('VAutocomplete', VAutocomplete)
  .mount('#app');
