import { createRouter, createWebHistory } from 'vue-router';

import ROUTES from '@/constants/routes';
import token from '@/adapters/token/token';


const routes = [
  {
    ...ROUTES.CARD_USER,
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/CardUserView.vue'),
  },
  {
    ...ROUTES.QR_CODE_GENERATOR,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "qr" */ '../views/QrCodeGeneratorView.vue'),

  },
  {
    ...ROUTES.LOGIN,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    ...ROUTES.HOME,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  }, {
    ...ROUTES.PROFILE_CONTACTS,
    meta: { requiresAuth: true, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileContactView.vue'),
  },
  {
    ...ROUTES.REGISTER,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
  },
  {
    ...ROUTES.PROFILE_ID,
    meta: { requiresAuth: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue'),
  },
  {
    ...ROUTES.COMPANY_ID,
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyView.vue'),
  },
  {
    ...ROUTES.EDIT_COMPANY_ID,
    meta: { requiresAuth: true, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/EditCompanyView.vue'),
  },
  {
    ...ROUTES.COMPANY_STATISTIC_ID, meta: { requiresAuth: true, editor: true },
    component: () => import(/* webpackChunkName: "statistic" */ '../views/CompanyStatisticView.vue'),
  },
  {
    ...ROUTES.EDIT_PROFILE_ID,
    meta: { requiresAuth: true, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/EditProfileView.vue'),
  },
  {
    ...ROUTES.ASSOCIATE_TAGS_TO_ADDRESS,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "tag" */ '../views/AssociateTagsToAddressView.vue'),
  }, {
    ...ROUTES.ASSOCIATE_BUSINESS_CARD_TO_USER,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "business" */ '../views/AssociateBusinessCardToUserView.vue'),
  },
  {
    ...ROUTES.SUPPORT_CARD,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/SupportCardView.vue'),
  }, {
    ...ROUTES.GET_TOKEN,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/GetTokenView.vue'),
  },

  {
    ...ROUTES.RENEW_PASSWORD,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */  '../views/RenewPassword.vue'),
  },
  {
    ...ROUTES.ADMIN,
    meta: { requiresAuth: true, editor: true },
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue'),
  },
  {
    ...ROUTES.CHATBOT,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "chatbot" */ '../views/ChatbotView.vue'),
  }, {
    ...ROUTES.VIRTUAL_CARD,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "virtualCard" */ '../views/VirtualCardView.vue'),
  }


];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && token.hasToken() === false) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: ROUTES.LOGIN.path,
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }
})

export default router;
